import { useState } from "react";
import { Container, Modal } from "react-bootstrap";

const Prakata = () => {
  const [bmod, setBmod] = useState(false);

  return (
    <section id="prakata" className="wow fadeInUpBig">
      <Container>
        <Container className="header-section">
          <h1>PRAKATA</h1>
          <p style={{ textAlign: "justify" }}>
            Salam Sejahtera untuk kita semua, semoga Tuhan selalu memberkati
            kita semua, Amin. Pertama-tama ijinkanlah kami menyampaikan beberapa
            kata untuk memperkenalkan Web Tajako.com yang baru kami luncurkan
            untuk membantu masyarakat dalam memberikan saran dan
            pendapat-pendapat hukum.
          </p>
          <p style={{ textAlign: "justify" }}>
            Tajako.com merupakan web yang akan memberikan para pembaca dan
            pengakses untuk mendapatkan informasi-informasi yang berkaitan
            dengan hukum ataupun permasalahan yang membutuhkan kajian hukum. Web
            Tajako.com diharapkan dapat menjadi salah satu solusi bagi
            masyarakat jika membutuhkan suatu pendapat hukum dalam penyelesaian
            permasalahan yang terjadi.{" "}
            <span
              style={{ color: "#0c2e8a" }}
              role="button"
              onClick={() => setBmod(true)}
            >
              Lanjutkan membaca..
            </span>
          </p>
        </Container>
      </Container>

      <Modal size="lg" show={bmod} onHide={() => setBmod(false)}>
        <Modal.Header closeButton>
          <Modal.Title>PRAKATA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              marginBottom: "10px",
              textJustify: "inter-word",
              textAlign: "justify",
            }}
          >
            Salam Sejahtera untuk kita semua, semoga Tuhan selalu memberkati
            kita semua, Amin. Pertama-tama ijinkanlah kami menyampaikan beberapa
            kata untuk memperkenalkan Web Tajako.com yang baru kami luncurkan
            untuk membantu masyarakat dalam memberikan saran dan
            pendapat-pendapat hukum
          </div>
          <div
            style={{
              marginBottom: "10px",
              textJustify: "inter-word",
              textAlign: "justify",
            }}
          >
            Tajako.com merupakan web yang akan memberikan para pembaca dan
            pengakses untuk mendapatkan informasi-informasi yang berkaitan
            dengan hukum ataupun permasalahan yang membutuhkan kajian hukum. Web
            Tajako.com diharapkan dapat menjadi salah satu solusi bagi
            masyarakat jika membutuhkan suatu pendapat hukum dalam penyelesaian
            permasalahan yang terjadi.
          </div>
          <div
            style={{
              marginBottom: "10px",
              textJustify: "inter-word",
              textAlign: "justify",
            }}
          >
            Konsultan hukum yang tersedia pada layanan konsultasi tajako.com
            merupakan orang-orang yang berkompeten pada bidangnya masing-masing.
            Masyarakat yang ingin mengakses berita-berita tentang hukum ataupun
            ingin menggunakan layanan konsultasi hukum, tentu saja dapat dengan
            tenang dan tidak perlu khawatir akan kualitas yang disediakan oleh
            tajako.com.
          </div>
          <div
            style={{
              marginBottom: "10px",
              textJustify: "inter-word",
              textAlign: "justify",
            }}
          >
            Kami para pendiri Tajako.com juga selalu berkomitmen untuk terus
            tumbuh dan berkembang seiring dengan waktu dan kebutuhan dari
            masyarakat, khususnya masyarakat di Indonesia. Dalam proses
            pertumbuhan tersebut kami juga tentunya memerlukan dukungan dari
            masyarakat Indonesia yang dapat membantu kami menjadi sebuah web
            maupun aplikasi yang berguna bagi Nusa dan Bangsa.
          </div>
          <div
            style={{
              marginBottom: "10px",
              textJustify: "inter-word",
              textAlign: "justify",
            }}
          >
            Kami para pendiri Tajako.com pada akhirnya mengucapkan banyak terima
            kasih atas dukungan seluruh pihak hingga Tajako.com resmi kami
            luncurkan dan hadir ditengah masyarakat yang modern ini. Semoga
            Tajako.com dapat menjadi inspirasi dan solusi bagi masyarakat di
            Indonesia maupun Internasional.
          </div>
          <div
            style={{
              marginBottom: "10px",
              textJustify: "inter-word",
              textAlign: "justify",
            }}
          >
            Atas perhatiannya kami ucapkan banyak terima kasih. Salam sukses dan
            sejahtera.
          </div>
          <div style={{ fontWeight: "bold" }}>Tajako.com</div>
        </Modal.Body>
      </Modal>
    </section>
  );
};
export default Prakata;

import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const imgProfile = require("./assets/img/about-img.jpg");
const Profile = () => {
  return (
    <section
      id="profile"
      className="wow fadeInUpBig align-middle py-8"
      data-wow-duration="1s"
    >
      <Container>
        <Row>
          <Col className="col-lg-6 col-md-12">
            <Container>
              <img src={imgProfile} alt="profile"></img>
            </Container>
          </Col>
          <Col className="col-lg-6 col-md-12">
            <Container id="profile-detail">
              <h1>Visi</h1>
              <h3 style={{ fontSize: "14px", textAlign: "justify" }}>
                Kami berkomitmen untuk meningkatkan kepatuhan hukum dan menjadi
                media konsultasi hukum terbaik di Indonesia melalui edukasi dan
                teknologi.
              </h3>
              <ListGroup className="custom-list-group">
                <ListGroupItem style={{ fontSize: "14px" }}>
                  <IoMdCheckmarkCircleOutline size={25} /> Membantu menciptakan
                  kondisi masyarakat sadar akan hukum.
                </ListGroupItem>
                <ListGroupItem style={{ fontSize: "14px" }}>
                  <IoMdCheckmarkCircleOutline size={25} /> Meningkatkan
                  kepatuhan masyarakat terhadap hukum yang berlaku.
                </ListGroupItem>
                <ListGroupItem style={{ fontSize: "14px" }}>
                  <IoMdCheckmarkCircleOutline size={25} />
                  Membantu penegakan hukum dalam kehidupan bermasyarakat.
                </ListGroupItem>
                <ListGroupItem style={{ fontSize: "14px" }}>
                  <IoMdCheckmarkCircleOutline size={25} />
                  Menjadi media konsultasi terbaik di Indonesia yang berbasis
                  teknologi
                </ListGroupItem>
              </ListGroup>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Profile;

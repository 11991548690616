import { Button, Col, Container, Row } from "react-bootstrap"

const CallBanner = () => {
    return (
        <Container fluid id="banner-call" className="wow fadeInUpBig">
            <Container className="items">
                <Row>
                    <Col className="description col-lg-9 col-md-12">
                        <h1>Aplikasi Mobile</h1>
                        <p style={{ textAlign: 'justify' }}>Konsultan hukum yang tersedia pada layanan konsultasi tajako.com merupakan orang-orang yang berkompeten pada bidangnya masing-masing. Masyarakat yang ingin mengakses berita-berita tentang hukum ataupun ingin menggunakan layanan konsultasi hukum, tentu saja dapat dengan tenang dan tidak perlu khawatir akan kualitas yang disediakan dalam bentuk Mobile App</p>
                    </Col>

                    <Col className="button col-lg-3 col-md-12">
                        <Button>Download</Button>
                    </Col>
                </Row>

            </Container>
        </Container >
    )

}
export default CallBanner
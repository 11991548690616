import { Col, Container, Row } from "react-bootstrap";
import { CiLocationOn } from "react-icons/ci";
import { BsTelephone, BsWhatsapp } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";

const sizeIcon = 40;
const ContactUs = () => {
  return (
    <section id="contact-us" className="wow fadeInUpBig">
      <Container>
        <Container className="header-section">
          <h1>KONTAK</h1>
          <p style={{ textAlign: "justify" }}>
            Konsultan hukum yang tersedia pada layanan konsultasi tajako.com
            merupakan orang-orang yang berkompeten pada bidangnya masing-masing.
            Masyarakat yang ingin mengakses berita-berita tentang hukum ataupun
            ingin menggunakan layanan konsultasi hukum, tentu saja dapat dengan
            tenang dan tidak perlu khawatir akan kualitas yang disediakan oleh{" "}
            <span style={{ fontWeight: "bold" }}>tajako.com</span>
          </p>
        </Container>
        <Container className="items">
          <Row>
            <Col className="col-lg-3 col-md-4 col-12">
              <div
                role="button"
                onClick={() => {
                  window.open(
                    "https://maps.google.com/maps?q=1.1222366094589233%2C104.09624481201172&z=17&hl=en"
                  );
                }}
                className="item"
              >
                <CiLocationOn size={sizeIcon} />
                <h3>Alamat:</h3>
                <p>
                  Komplek Ruko Pesona Niaga Blok D No.6, Kelurahan Belian,
                  Kecamatan Batam Kota, Kota Batam
                </p>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-4 col-12">
              <div
                role="button"
                className="item"
                onClick={() => {
                  window.open("tel:6282199583526");
                }}
              >
                <BsTelephone size={sizeIcon} />
                <h3>Telepon:</h3>
                <div>
                  <p>+62821-9958-3526</p>
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-4 col-12">
              <div
                role="button"
                onClick={() => {
                  window.open("mailto:dwasukses0288@gmail.com");
                }}
                className="item"
              >
                <AiOutlineMail size={sizeIcon} />
                <h3>Email:</h3>

                <div>
                  <p>dwasukses0288@gmail.com</p>
                </div>
              </div>
            </Col>
            <Col className="col-lg-3 col-md-4 col-12">
              <div
                role="button"
                onClick={() => {
                  window.open("https://wa.me/6282199583526");
                }}
                className="item"
              >
                <BsWhatsapp size={sizeIcon} />
                <h3>Whatsapp:</h3>
                <div>
                  <p>+62821-9958-3526</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3989.0530183731544!2d104.09366987496534!3d1.1222365988670129!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMcKwMDcnMjAuMSJOIDEwNMKwMDUnNDYuNSJF!5e0!3m2!1sen!2sid!4v1741938600806!5m2!1sen!2sid"
          allowFullScreen
          aria-hidden="false"
          tabIndex="0"
          title="Dewa Sukses"
        ></iframe>

        {/* <Form className="form">
          <Form.Group controlId="formBasicEmails">
            <Row>
              <Col>
                <Form.Control type="text" placeholder="Your Name" />
              </Col>
              <Col>
                <Form.Control type="email" placeholder="Your Email" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control type="text" placeholder="Subject" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control as="textarea" rows={5} placeholder="Message" />
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <Button variant="primary" type="submit">
                  Send Message
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Form> */}
      </Container>
    </section>
  );
};
export default ContactUs;

import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { BsBook } from "react-icons/bs";
import { FaBalanceScale, FaMobileAlt, FaPeopleCarry } from "react-icons/fa";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const sizeIcon = 90;
const iconMaps = {
  FaPeopleCarry: FaPeopleCarry,
  FaMobileAlt: FaMobileAlt,
  BsBook: BsBook,
  FaBalanceScale: FaBalanceScale,
};

const Services = (dataJson) => {
  return (
    <section id="services">
      <Container>
        <Container className="header-section" style={{ alignItems: "center" }}>
          <h1>Misi</h1>

          <ListGroup className="custom-list-group">
            <ListGroupItem style={{ fontSize: "14px", border: "none" }}>
              <IoMdCheckmarkCircleOutline size={25} color="#50d8af" />{" "}
              Memberikan edukasi kepada masyarakat tentang peraturan-peraturan
              yang berlaku dalam kehidupan masyarakat di Indonesia.
            </ListGroupItem>
            <ListGroupItem style={{ fontSize: "14px", border: "none" }}>
              <IoMdCheckmarkCircleOutline size={25} color="#50d8af" />{" "}
              Memberikan pendapat hukum kepada masyarakat agar dapat membantu
              menyelesaikan permasalahan hukum yang ada.
            </ListGroupItem>
            <ListGroupItem style={{ fontSize: "14px", border: "none" }}>
              <IoMdCheckmarkCircleOutline size={25} color="#50d8af" />
              Membantu mensosialisasikan peraturan perundangan yang baru
              dirancang hingga peraturan yang sudah disahkan oleh Pemerintah
              kepada masyarakat.
            </ListGroupItem>
            <ListGroupItem style={{ fontSize: "14px", border: "none" }}>
              <IoMdCheckmarkCircleOutline size={25} color="#50d8af" />
              Memberikan fasilitas konsultasi hukum kepada masyarakat dalam
              memahami hukum, dengan mempertemukan konsultan dengan masyarakat.
            </ListGroupItem>
          </ListGroup>
        </Container>

        <Container id="services-box">
          <Row>
            {dataJson.data.services.map((data) => {
              const IconComponent = iconMaps[data.icon];
              return (
                <Col
                  key={data.id}
                  className={`col-lg-6 col-md-12 wow ${data.wowClass}`}
                  data-wow-duration={`${data.wowClassDuration}`}
                >
                  <Container className="services-box-detail">
                    <IconComponent size={sizeIcon} />
                    <Container>
                      <h2>{data.title}</h2>
                      <p>{data.detail}</p>
                    </Container>
                  </Container>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
    </section>
  );
};
export default Services;
